<template>
    <div class="comData" :style="{paddingTop: landingPagePaddingTop + 'px', paddingBottom: landingPagePaddingBot + 'px'}">
        <el-form style="margin-top: 20px" :model="ruleForm" :rules="rules" ref="ruleForm">
            <div v-for="(item, index) in pageComponentData" :key="index" :index="index">
                <component
                    v-if="item.component != 'v-text'"
                    :is="item.component"
                    :style="item.style"
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
                <component
                    :is="item.component"
                    v-else
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :style="getComponentStyle(item.style)"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
            </div>
        </el-form>
    </div>
</template>
 
<script>

import formItem from '../../minxins/form-item';
import { getQueryVariable } from '../../utils'
export default {
    name: 'flow-page',
    mixins: [formItem],
    components: {
        // 注册组件
       
    },
    data() {
        return {
            rules: {},
            ruleForm: {},
            landingPagePaddingBot: 0,
            landingPagePaddingTop: 0,
        }
    },
    computed: {
        pageComponentData() {
            return this.$store.state.flowPage.tipPageComponentData;
        }
    },
    watch: {
      pageComponentData: {
        handler(newVal) {
          this.$nextTick(() => {
             let elTH = 0, elBH = 0;
              newVal.map(item => {
                if (item.style.isDown === 0) {//组件打开了吸底
                  elBH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                } else if (item.style.isUp === 0) {//组件打开了吸顶
                  elTH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                }
              }, 0)
              // 画布顶部、底部内边距
              this.landingPagePaddingTop = elTH
              this.landingPagePaddingBot = elBH
          })
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
        try {
            let id = getQueryVariable('id');
            console.log(id, !this.$store.state.flowPage.perview)
            if (!this.$store.state.flowPage.perview && id) {//不是预览，并且有id，是支付后重定向回到成功页面
                this.getFlowPageById(id);//获取落地页组件详情
                this.getUrlParams();//获取巨量引擎参数
            }
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
        // 按钮点击事件
        hanlderBtn(btnType) {
            if (btnType === 'v-button') {//普通按钮
                this.submitBtn(this.pageComponentData);
            } else if (btnType === 'v-buttonPay' || btnType === 'v-pay') {
                this.submitBtn(this.pageComponentData);
            } else if (btnType === 'v-PhoneText') {//手机验证码，先收集用户数据
                this.submitFormData(this.pageComponentData);
            }
        },
    }
}
</script>
 
<style lang="scss" scoped>
@import url('../../styles/form-item.scss');
</style>